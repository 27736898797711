.cursor-pointer {
  cursor: pointer;
}

.opacity-45 {
  opacity: 45%;
}

.font-1dot5em {
  font-size: 1.5em;
}

.not-selected {
  color: #6c6c6c;
}

.justify-content-right {
  justify-content: right;
}

.icon {
  color: white
}
.not-visited {
  color: rgba(255, 255, 255, 0.6);
}
.icon-open {
  color: rgba(98, 255, 0, 0.35)
}
.icon-temporary {
  color: gold
}
.icon-closed {
  color: red
}
.icon-plane {
  color: skyblue;
}
.icon-hotel {
  color: orange;
}
.icon-pen {
  color: #00efb2;
}
.icon-bonus {
  color: #007df6;
}

.record-visit {
  margin-top: 8px;
}
.info-window {
  color: black;
}
.info-header {
  font-weight: bold;
  margin-bottom: 5px;
}
.info-vicinity {
  margin-bottom: 8px;
}
.record-visit {
  margin-top: 8px;
}

.userIcon {
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;
  margin-right: 10px;
  border-radius: 30px;
}
.gold-pos {
  background-color: #FFD700;
  color: black;
}
.silver-pos {
  background-color: #c0c0c0;
  color: black;
}
.bronze-pos {
  background-color: #cd7f32;
  color: black;
}

.loading {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
  font-size: 20pt;
  font-weight: bold;
}

.user-header {
  text-align: center;
  font-size: 2em;
  padding: 0.2em;
  background-color: #6be3ff57;
  border-radius: 3px;
  margin-bottom: 10px;
}

.location-header {
  text-align: center;
  font-size: 2em;
  padding: 0.2em;
  background-color: #6bffa157;
  border-radius: 3px;
  margin-bottom: 0.3em;
}

.user-tag {
  display: inline-block;
  background-color: #6969696b;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
  color: white;
  margin-bottom: 0.2em;
  max-width: 180px;
}
.edit-tag {
  display: inline-block;
  background-color: #6969696b;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  color: white;
  margin-bottom: 1em;
  max-width: 180px;
}

.symbol-tag {
  display: inline-block;
  background-color: #6969696b;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
  color: white;
  margin-bottom: 0.2em;
}

.location-tag {
  display: inline-block;
  background-color: #6969696b;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
  color: white;
}

.news-item {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  border-bottom: 1px solid grey;
}

.pub-row {
  padding-top: 0.8em !important;
  padding-bottom: 0.6em !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-bottom: 1px solid grey;
}

.leaderboard-row {
  border-bottom: 1px solid grey;
  margin-bottom: 0.5em;
}

.user-row {
  border-bottom: 1px solid grey;
  padding: 1em;
}

.active-session {
  background-color: #6fe6468a;
}

.sub-menu-nav {
  font-size: 0.9em
}

.gm-style-iw-chr {
  height: 25px;
  display: flex;
  margin-right: -5px;
}

.bullet-points {
  margin-left: -20px;
  margin-bottom: 0px;
}

.message {
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.6em;
  padding-bottom: 0.2em;
  border-bottom: 1px solid #5e5e5e;
}

.message-view {
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse !important;
  height: 53vh;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  /*border-radius: 10px;*/
}
::-webkit-scrollbar-track {
  /*background-color: black;*/
}
html {
  /*scrollbar-width: thin;*/
  /*scrollbar-color: black grey;*/
}

.chat-bubble {
  max-width: 75%;
  width: fit-content;
  padding: 6px 12px;
  margin: 10px;
  border-radius: 10px;
  color: black;
  background-color: #f1f1f1;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.chat-bubble-right {
  background-color: #0084ff;
  color: white;
  text-align: left;
  align-self: flex-end;
}
.chat-message {
  display: flex;
  flex-direction: column;
}

.chat-input {
  width: 88%;
}

@media (min-width: 768px) {
  .chat-input {
    width: 95%;
  }
}
.photo-display {
  text-align: center;
  float: left;
  width: 104px;
  height: 104px;
  border: dashed 2px lightgray;
}
.mini-photo {
  width: 100px;
}
.super-mini-photo {
  width: 50px;
}
.thumbnail {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.full-size-photo {
  margin-top: 0em;
  width: 324px;
  height: 240px;
  cursor: pointer;
}
.photo-upload-label {
  padding-top: 1.7em;
  padding-bottom: 1.7em;
  font-weight: bold;
  cursor: pointer;
}
.album {
  text-align: center;
  margin: 0.5em;
}
.photo {
  display: inline-block;
  border: solid 2px #30303000;
  width: 328px;
  height: 328px;
  margin: 0.5em;
  box-shadow: 0 0px 8px #ffffff94;
  border-radius: 5px;
}
.card-alt-padding {
  padding-top: 0.7em;
  padding-bottom: 0.5em;
  padding-left: 0.7em;
  padding-right: 0.7em;
}
.logo {
  font-weight: bold;
  font-size: 10pt;
  max-width: 100px;
  text-align: center;
  background-color: #6969696b;
  border-radius: 10px;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.bold-text {
  font-weight: bold;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px !important;
    margin: 1.75rem auto;
  }
}